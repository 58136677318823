.planner-horizontal-container {
    display: flex;
    overflow-x: auto; /* Enables horizontal scrolling */
    gap: 16px; /* Space between day columns */
    scrollbar-width: thin;
    padding: 10px;
  }
  
  .planner-horizontal-container::-webkit-scrollbar {
    height: 8px; /* Horizontal scrollbar height */
  }
  
  .planner-horizontal-container::-webkit-scrollbar-thumb {
    background: #888; /* Scrollbar color */
    border-radius: 4px;
  }
  
  .planner-horizontal-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .planner-day-column {
    min-width: 200px; /* Ensure all day columns are the same width */
    flex-shrink: 0; /* Prevent columns from shrinking */
  }
  
  .planner-day-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
    text-align: center;
    color: #333;
  }
  
  .planner-schedules {
    display: flex;
    flex-direction: column; /* Arrange schedules vertically */
    gap: 12px; /* Space between schedule cards */
  }
  
  .schedule-card {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .no-schedule-text {
    color: gray;
    text-align: center;
  }
  