.weekly-planner .ant-table-tbody .ant-table-cell{
  padding: 0px !important;
}
/* Table Styling */
.schedule-table {
  width: 100%;
  margin: 20px 0;
  table-layout: fixed;
}

.schedule-table thead th {
  /* background-color: #007bff; */
  color: white;
  text-align: center;
  font-weight: bold;
  border: 1px solid #ddd;
  padding: 10px;
}

.schedule-table td {
  position: relative;
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
  background-color: #f8f9fa;
  cursor: pointer;
}

/* Occupied Cell with Schedules */
.occupied-cell {
  background-color: #ffffff;
  position: relative;
}

/* Time Slot Cell */
.time-slot {
  position: relative;
  border: 1px solid #ddd;
  background-color: #f8f9fa;
  height: 60px;
  text-align: center;
  padding: 0;
  overflow: hidden;
}

/* Container for Flex Layout */
.class-header {
  font-weight: bold;
  text-align: center;
}

.time-slot-container {
  padding: 0px;
  min-height: 40px;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/* Half Slots */
.half-slot {
  flex: 1;
  height: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ddd;
  cursor: pointer;
}

.half-slot:last-child {
  border-right: none;
}

.half-slot.empty {
  background-color: #ffffff;
}

.half-slot.occupied {
  font-weight: bold;
}

.half-slot.empty:hover {
  background-color: #f1f1f1;
}

/* Merged Schedules */
.schedule.merged {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}